<template>
  <div class="table-wrapper block-el">
    <!-- Calendar search -->
    <div class="row">
      <div class="col-md-6">
        <slot name="buttons" />
      </div>
      <div v-if="searchable" class="col-md-6">
        <form @submit.prevent="search">
          <div class="form-group">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                v-model="q"
              />
              <div class="input-group-append">
                <button class="btn icon_btn m-0" type="submit">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--/Calendar search -->
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th v-for="(column, n) in filteredColumns" :key="n">
                  {{ $tc(`app.${getColumnLabel(column)}`, 1) }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, n) in data" :key="n">
                <td v-for="(column, i) in filteredColumns" :key="i">
                  <span
                    :class="column.class"
                    v-html="getColumnValue(item, column)"
                  >
                  </span>
                </td>
                <td>
                  <slot name="actions" v-bind="item"></slot>
                </td>
              </tr>
              <tr v-if="!data.length">
                <td class="text-center" :colspan="columns.length">
                  <LottieAnimNoData />
                  {{ $tc('app.empty_table') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row mt-auto pagination_row">
      <div class="col-12">
        <pagination :lastPage="pages" @change="changePage" />
      </div>
    </div>
    <!-- <div v-if="loading" class="qt-block-ui"></div> -->
    <div class="loading min-h-300" v-if="loading">
      <LoadingAnim />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import Pagination from '@/components/rebranding/Pagination.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'DataTable',
  components: {
    LottieAnimNoData,
    Pagination,
    LoadingAnim,
  },
  props: {
    searchable: Boolean,
    loading: Boolean,
    qSearch: {
      type: String,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    pages: Number,
  },
  data() {
    return {
      q: '',
    };
  },
  computed: {
    filteredColumns() {
      const filteredColumns = [];

      this.columns.forEach((column) => {
        if (!column.hide) filteredColumns.push(column);
      });
      return filteredColumns;
    },
  },
  mounted() {
    this.$root.$on('UsersCard', () => {
      // your code goes here
      this.search();
    });
  },
  methods: {
    getColumnLabel(value) {
      if (value.label) return value.label;
      return this.getColumnKey(value);
    },
    getColumnKey(value) {
      return typeof value === 'string' ? value : value.key;
    },
    getColumnValue(item, column) {
      const keys = this.getColumnKey(column).split('.');

      for (let i = 0; i < keys.length; i += 1) {
        item = item[keys[i]];
        if (Array.isArray(item)) {
          item = item[0] ? item[0] : '';
        }
      }
      if (item === true) {
        return '<span class="material-symbols-outlined text-yup"> done </span>';
      }
      if (item === false) {
        return '';
      }

      if (column.filters) {
        column.filters.forEach((filter) => {
          const filterParams = filter.split('|');
          item = Vue.filter(filterParams[0])(item, filterParams[1]);
        });
      }
      if (column.localize) {
        const loc = this.$tc(`app.${item}`, 1);

        if (loc.indexOf('app.') === -1) item = loc;
      }
      return item;
    },
    changePage(page) {
      this.$emit('change-page', page);
    },
    search() {
      console.log('What arrives (DataTable): ', this.qSearch);
      this.$emit('search', this.q);
    },
  },
};
</script>

<style scoped lang="scss">
/* Rebranding */
.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.table-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

</style>
